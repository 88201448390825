import React, { FC, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isTruthy, maxRating, generateList, setInnerHtml } from 'utils';
import { Modal } from 'components/modal';
import { Reviews, Review, Language } from 'types';
import { AddReviewForm } from './addReviewForm';

interface Props {
  reviews: Reviews;
  locale: Language;
}
  
export const ReviewsList: FC<Props> = props => {
  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const renderStars = (review: Review) => (
    <div>
      {generateList(maxRating).map(index => (
        <i key={index} className={classNames('icon inline-block ml-3px text-yellow', {
          'star-filled': Number(review?.rating) >= index + 1,
          'star-empty': Number(review?.rating) < index + 1 
        })} />
      ))}
    </div>
  );

  const getFormattedTitle = (review: Review) => [
    review?.name,
    intl.formatDate(review?.date, { year: 'numeric', month: 'narrow', day: '2-digit' }),
    review?.city
  ].filter(isTruthy).join(' · ');

  const renderTitle = (review: Review) => (
    <div>
      {getFormattedTitle(review)}
    </div>
  );

  const renderReviewBlock = (item: Reviews[0], index: number) => (
    <div key={index} className="mb-15px hover:shadow-lg">
      <div className="review-header p-13px text-white bg-brown-500 text-14px flex justify-between">
        {renderTitle(item.review)}
        {renderStars(item.review)}
      </div>
      <div
        className="review-content p-13px bg-sand-100 italic"
        dangerouslySetInnerHTML={setInnerHtml(item.content || '')} 
      />
    </div>
  );

  const renderAddReviewButton = () => (
    <div
      tabIndex={0}
      role="button"
      aria-label="Write review button"
      onClick={toggleModal}
      className="bg-brown-400 text-white hover:bg-yellow-400 p-6px text-base inline-block mt-10px mb-20px cursor-pointer select-none"
    >
      <FormattedMessage
        id="reviews.add_review"
        defaultMessage="Parašyti atsiliepimą"
      />
    </div>
  );

  const renderReviewFormModal = () => (
    <Modal
      isOpen={isModalOpen}
      onClose={toggleModal}
      size="md"
      header={<FormattedMessage id="reviews.add_review" defaultMessage="Parašyti atsiliepimą" />}
    >
      <AddReviewForm locale={props.locale} />
    </Modal>
  );

  const renderReviews = () => props.reviews.map(renderReviewBlock);

  return (
    <div>
      {/* {renderAddReviewButton()} */}
      {renderReviews()}
      {renderReviewFormModal()}
    </div>
  );
};