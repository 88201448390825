import React, { FC, useState, useMemo } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { WithLoader } from 'components/withLoader';
import { Form, FormField, SubmitFormResponse, validateRequired, validateEmail } from 'components/form';
import { generateUrl, callApi } from 'utils';
import { Data, Props, Response, SystemResponse } from './types';

const getInitialData = (): Data => ({
  name: '',
  city: '',
  date: '',
  email: '',
  rating: '',
  reviewMessage: '',
  showEmail: false
});

const messages = defineMessages({
  name: {
    id: 'form.name',
    defaultMessage: 'Vardas'
  },
  email: {
    id: 'form.email',
    defaultMessage: 'El.paštas'
  },
  city: {
    id: 'review_form.city',
    defaultMessage: 'Miestas',
  },
  showEmail: {
    id: 'review_form.show_email',
    defaultMessage: 'Rodyti el.paštą'
  },
  rating: {
    id: 'review_form.rating',
    defaultMessage: 'Įvertinimas'
  },
  review: {
    id: 'review_form.review',
    defaultMessage: 'Atsiliepimas'
  },
  saveSuccess: {
    id: 'review_form.save_success',
    defaultMessage: 'Jūsų atsiliepimas buvo išsaugotas. Jis bus matomas kai tik bus patvirtintas svetainės administratoriaus.'
  },
  saveError: {
    id: 'review_form.save_error',
    defaultMessage: 'Deja, nepavyko išsaugoti Jūsų atsiliepimo.'
  }
});

export const AddReviewForm: FC<Props> = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [data, setData] = useState<Data>(getInitialData());
  const intl = useIntl();

  const formFields = useMemo<FormField<Data>[]>(() => [
    {
      key: 'name',
      type: 'text',
      placeholder: intl.formatMessage(messages.name),
      validators: [validateRequired]
    },
    {
      key: 'city',
      type: 'text',
      placeholder: intl.formatMessage(messages.city),
      validators: [validateRequired]
    },
    {
      key: 'email',
      type: 'text',
      placeholder: intl.formatMessage(messages.email),
      validators: [validateRequired, validateEmail]
    },
    {
      key: 'showEmail',
      type: 'toggle',
      placeholder: intl.formatMessage(messages.showEmail),
      validators: []
    },
    {
      key: 'rating',
      type: 'rating',
      placeholder: intl.formatMessage(messages.rating),
      validators: [validateRequired]
    },
    {
      key: 'reviewMessage',
      type: 'textarea',
      placeholder: intl.formatMessage(messages.review),
      validators: [validateRequired]
    }
  ], [intl]);

  const isSystemResponse = (response: Response): response is SystemResponse => (response as SystemResponse).code !== undefined;

  const normalizeResponse = (response: Response | null): SubmitFormResponse<Data> => {
    if (response === null) {
      return {
        isSuccess: false,
        message: intl.formatMessage(messages.saveError),
        validationError: {}
      };
    }

    if (isSystemResponse(response)) {
      return {
        isSuccess: false,
        message: intl.formatMessage(messages.saveError),
        validationError: response.data.params ? response.data.params : {}
      };
    }

    if (!response.success) {
      return {
        isSuccess: false,
        message: intl.formatMessage(messages.saveError),
        validationError: response.errors ? response.errors : {}
      };
    }

    return {
      isSuccess: true,
      message: '',
      validationError: {}
    };
  };

  const handleSubmit = async (formFields: Data): Promise<SubmitFormResponse<Data>> => {
    try {
      setShowLoader(true);
      const nonce = await callApi(generateUrl('/almuka/v1/review-nonce'), 'GET', {}, 'application/json');
      if (!nonce) throw new Error('Unable to fetch nonce');
      const url = generateUrl('/almuka/v1/reviews') + '?nonce=' + nonce;
      const response = await callApi<Response, Data>(url, 'POST', formFields, 'application/json');
      setShowLoader(false);
      return normalizeResponse(response);
    } catch (err) {
      console.error('Unable to save review');
      setShowLoader(false);
      return {
        isSuccess: false,
        message: intl.formatMessage(messages.saveError),
        validationError: {}
      };
    }
  };

  const handleChange = (key: keyof Data, value: Data[keyof Data]) => {
    setData(oldState => ({ ...oldState, [key]:  value }));
  };

  const handleSuccess = () => {
    setData(getInitialData());
  };

  return (
    <WithLoader showLoader={showLoader}>
      <Form
        onSuccess={handleSuccess}
        onSubmit={handleSubmit}
        onChange={handleChange}
        data={data}
        fields={formFields}
        sendSuccess={intl.formatMessage(messages.saveSuccess)}
        sendError={intl.formatMessage(messages.saveError)}
      />
    </WithLoader>
  );
};