import React, { FC } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Table } from 'components/table';
import { StaticTableData } from 'types';
import { getStaticTableColumns } from 'utils';

const messages = defineMessages({
  accountTitle: {
    id: 'contacts.account_title',
    defaultMessage: 'Sąskaita avansui'
  },
  receiver: {
    id: 'contacts.receiver_label',
    defaultMessage: 'Gavėjas'
  },
  paymentPurposeLabel: {
    id: 'contacts.payment_purpose_label',
    defaultMessage: 'Mokėjimo paskirtis'
  },
  paymentPurposeValue: {
    id: 'contacts.payment_purpose_value',
    defaultMessage: 'Už kambarių nuomą (prašome nurodyti nuomos laikotarpį)'
  },
  bankAccountLabel: {
    id: 'contacts.bank_account_label',
    defaultMessage: 'Gavėjo bankas {number}'
  },
  bankAccountValue: {
    id: 'contacts.bank_account_value',
    defaultMessage: '{name}, Swift: {swift}, Sąskaitos nr: {account}'
  }
});
  
export const AccountTable: FC = () => {
  const columns = getStaticTableColumns();
  const intl = useIntl();
  const rows: StaticTableData[] = [
    {
      left: intl.formatMessage(messages.receiver),
      right: 'Petras Viskontas'
    },
    {
      left: intl.formatMessage(messages.paymentPurposeLabel),
      right: intl.formatMessage(messages.paymentPurposeValue)
    },
    {
      left: intl.formatMessage(messages.bankAccountLabel, { number: 1 }),
      right: intl.formatMessage(messages.bankAccountValue, { 
        name: 'SEB',
        swift: 'CBVILT2X',
        account: 'LT747044064000562394'
      })
    },
    {
      left: intl.formatMessage(messages.bankAccountLabel, { number: 2 }),
      right: intl.formatMessage(messages.bankAccountValue, { 
        name: 'Swedbank',
        swift: 'HABALT22',
        account: 'LT737300010071039311'
      })
    }
  ];

  return (
    <>
      <h3 className="mt-15px mb-15px text-xl font-bold">
        <FormattedMessage {...messages.accountTitle} />
      </h3>
      <Table
        className="account-info w-full"
        showHeader={false}
        responsive
        rows={rows}
        columns={columns}
      />
    </>
  );
};