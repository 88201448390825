import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Gallery } from '@designus/react-simple-gallery/preact';
import { defaultLanguage, mapGraphqlMediaToGalleryImages, setInnerHtml } from 'utils';
import { withIntlProvider } from 'components/withIntl';
import { Layout } from 'components/layout';
import { FeaturedImage } from 'types';
import { PriceTable } from './priceTable';
import { ReviewsList } from './reviewsList';
import { AccountTable } from './accountTable';
import { SinglePageProps } from './types';

const SinglePageTemplate = (props: SinglePageProps) => {
  const { page } = props.data;
  const locale = props.pageContext.locale || defaultLanguage;
  const { showSlider = false, showPriceTable = false, showReviews, showAccount } = page?.pageAttributes?.attributes || {};
  const featuredImageNode = page?.featuredImage?.node;

  const featuredImage: FeaturedImage = {
    data: featuredImageNode?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImageNode?.mediaAttribute?.alt?.[locale] || '',
    order: 0
  };
  
  const images = mapGraphqlMediaToGalleryImages(locale, page?.pageAttributes?.gallery);

  const showGallery = images.length > 0;

  return (
    <Layout
      location={props.location}
      title={page?.title || ''}
      seoTitle={page?.title || ''}
      showSlider={showSlider}
      featuredImage={featuredImage}
    >
      {renderProps => (
        <article
          itemScope
          itemType="http://schema.org/Article"
        >

          {showPriceTable && (
            <PriceTable rooms={renderProps.rooms} />
          )}

          {showReviews && (
            <ReviewsList
              reviews={renderProps.reviews}
              locale={props.pageContext.locale || defaultLanguage}
            />
          )}

          {Boolean(page?.content) && (
            <section
              itemProp="articleBody"
              dangerouslySetInnerHTML={setInnerHtml(page?.content || '')}
            >
            </section>
          )}

          {showAccount && (
            <AccountTable />
          )}

          {showGallery && (
            <Gallery
              transition="none"
              images={images}
              renderThumbImage={image => (
                <GatsbyImage
                  image={image.thumb}
                  alt={image.alt || ''}
                />
              )}
              renderFullImage={image => (
                <GatsbyImage
                  image={image.full}
                  alt={image.alt || ''}
                  objectFit="contain"
                />
              )}
            />
          )}

        </article>
      )}
    </Layout>
  );
};

export default withIntlProvider(SinglePageTemplate);

export const pageQuery = graphql`
  query SinglePageData($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      featuredImage {
        ...FeaturedImageFields
      }
      pageAttributes {
        attributes {
          showPriceTable
          showSlider
          showReviews
          showAccount
        }
        gallery {
          ...GalleryFields
        }
      }
    }
  }
`;
