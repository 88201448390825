import React, { useMemo, FC } from 'react';
import { Link } from 'gatsby';
import { FormattedMessage, FormattedDate, useIntl } from 'react-intl';
import { getLocalizedPath, getRooms, isTruthy } from 'utils';
import { Table, TableColumn } from 'components/table';
import { NonNullable, ArrayElement, Rooms } from 'types';
import { Post, DateRange } from './types';
import '__generated__/gatsby-types';

type ColumnKey = keyof NonNullable<NonNullable<Post['roomAttributes']>['prices']>;

const periodMap: Record<ColumnKey, DateRange> = {
  period1: {
    from: '2021-01-01',
    to: '2021-06-01'
  },
  period2: {
    from: '2021-06-01',
    to: '2021-07-01'
  },
  period3: {
    from: '2021-07-01',
    to: '2021-09-01'
  },
  period4: {
    from: '2021-09-01',
    to: '2021-01-01'
  }
};

const priceGlosarry = [
  // <FormattedMessage
  //   key={1}
  //   id="price_condition_1"
  //   defaultMessage="Nuomos kaina 2 žmonėms. Už 1 papildomą žmogų - 6 €"
  // />,
  <FormattedMessage
    key={2}
    id="price_condition_2"
    defaultMessage="Kainos nurodytos be patalynes (5 €) ir pagalves mokesčio (2 € / asmeniui)"
  />,
  <FormattedMessage
    key={3}
    id="price_condition_3"
    defaultMessage="Kambarių nuomos kainos gali keistis šventiniu laikotarpiu."
  />
];

interface Props {
  rooms: Rooms
}

export const PriceTable: FC<Props> = props => {
  const intl = useIntl();
  const rooms = useMemo(() =>
    getRooms(props.rooms, intl.locale)
      .filter(room => {
        // Only show rooms with at least single price
        return Object.values(room.roomAttributes?.prices || {})
          .flatMap(item => [item?.priceFrom, item?.priceTo])
          .filter(isTruthy)
          .length > 0;
      }),
    [props.rooms, intl]
  );

  const renderPriceGlossary = () => (
    <ul className="italic pl-0 pt-15px">
      {priceGlosarry.map((item, index) => (
        <li className="list-decimal list-inside pl-15px" key={index}>
          {item}
        </li>
      ))}
    </ul>
  );

  const getPeriodHeader = (key: ColumnKey) => {
    const period = periodMap[key];
    return (
      <>
        <FormattedDate
          value={period.from}
          month="2-digit"
          day="2-digit"
        />{' - '}
        <FormattedDate
          value={period.to}
          month="2-digit"
          day="2-digit"
        />
      </>
    );
  };

  const getPeriodPrice = (key: ColumnKey, data: ArrayElement<typeof rooms>) => {
    const prices = data.roomAttributes?.prices?.[key];
    const price = [prices?.priceFrom, prices?.priceTo]
      .filter(isTruthy)
      .filter((item, index, arr) => arr.indexOf(item) === index)
      .join('-');
    if (!price) return '-';

    if (price.includes('-')) return `${price} €`;

    return (
      <FormattedMessage
        id="price_from"
        defaultMessage="nuo {price} €"
        values={{
          price: price
        }}
      />
    );
  };

  const getColumns = (): TableColumn<ArrayElement<typeof rooms>>[] => {
    return [
      {
        id: 1,
        align: 'left',
        headerName: (
          <FormattedMessage
            id="rooms"
            defaultMessage="kambariai"
          />
        ),
        cellRenderer: data => (
          <Link
            className="no-underline hover:underline"
            to={getLocalizedPath(intl.locale, data?.uri)}
          >
            {data.title}
          </Link>
        )
      },
      {
        id: 2,
        align: 'center',
        headerName: getPeriodHeader('period1'),
        cellRenderer: data => getPeriodPrice('period1', data)
      },
      {
        id: 3,
        align: 'center',
        headerName: getPeriodHeader('period2'),
        cellRenderer: data => getPeriodPrice('period2', data)
      },
      {
        id: 4,
        align: 'center',
        headerName: getPeriodHeader('period3'),
        cellRenderer: data => getPeriodPrice('period3', data)
      },
      {
        id: 5,
        align: 'center',
        headerName: getPeriodHeader('period4'),
        cellRenderer: data => getPeriodPrice('period4', data)
      }
    ];
  };

  return rooms.length > 0 ? (
    <>
      <Table
        columns={getColumns()}
        rows={rooms}
        showHeader
        responsive
        className="w-600px sm:w-full"
      />
      {renderPriceGlossary()}
    </>
  ) : null;
};
